import axiosRetry from 'axios-retry';

import { MirrorService } from 'services/MirrorService';
import { SelectOption } from 'types';
import { UserCard } from 'types/interfaces/user/UserCard';
import { UserVideoCardWithViews } from 'types/interfaces/user/UserVideoCardWithViews';

import { prelandIdFormPathname } from 'helpers/route';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import { TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY } from 'hooks/useSessionStartTrack';

import { FinderFilters } from './FinderApi';
import { httpClient } from './httpClient';

export enum OrderType {
  Boost = 'boost',
  Vip = 'vip',
  Credit = 'credit',
  VideoPromote = 'video_promote',
}

interface TrackResponse {
  trans_build_id: number;
}

interface ScreenParameters {
  screen: {
    orientation: 'landscape' | 'portrait';
    width: number;
    height: number;
  };
}

export type PaymentFormEvents =
  | 'mounted'
  | 'submit'
  | 'submit_success'
  | 'submit_fail'
  | '3ds_verify'
  | 'form_redirect'
  | 'form_interaction'
  | 'resize'
  | 'error'
  | 'card_brand_validated'
  | 'order_status_event';

export type PaymentFormMethods = 'card' | 'apple_pay';

export type PaymentFormInteractionType = 'button' | 'input';

export type PaymentFormInteractionName =
  | 'submit'
  | 'google_pay'
  | 'apple_pay'
  | 'card_number'
  | 'card_cvv'
  | 'card_expiry'
  | 'card_holder';

export type BackButtonTraffickChannels =
  | 'backbutton'
  | 'backbutton_api'
  | 'backbutton-retention';

interface TrackPaymentFormPayload extends ScreenParameters {
  orderId: string;
  billingToken?: string;
  event: PaymentFormEvents;
  raw: Record<string, any>;
  payMethod?: PaymentFormMethods;
  interactionType?: PaymentFormInteractionType;
  interactionName?: PaymentFormInteractionName;
  cardFormIsValid?: boolean;
  cardFormTouched?: boolean;
  cardNumberIsValid?: boolean;
  cardNumberTouched?: boolean;
  cardCvvIsValid?: boolean;
  cardCvvTouched?: boolean;
  cardExpiryIsValid?: boolean;
  cardExpiryTouched?: boolean;
  eventParam?: any;
  resizeWidth?: number;
  resizeHeight?: number;
}

const getBuildVersionFromSessionStorage = () => {
  return (
    getSessionStorageItem(TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY) || '0'
  );
};

export const TrackingApi = {
  async track(payload?: { autologinToken: string }) {
    const autologinToken = payload?.autologinToken;
    const prelandId = prelandIdFormPathname(document.location.pathname);
    const historyLength =
      window.history.length > 1 ? window.history.length : null;

    const { data } = await httpClient.get<TrackResponse>('/track', {
      params: {
        preland_id: prelandId,
        history_length: historyLength,
        ...(Boolean(autologinToken) && { login_token: autologinToken }),
      },
    });

    return data;
  },

  trackError(payload: {
    type?: 'error' | 'warning';
    message: string;
    params?: any;
  }) {
    const url = payload.type === 'error' ? '/log/error' : '/log/warning';

    return httpClient.post(url, {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  async logRegistrationStep({
    prelandId,
    screenNumber,
    emailValidation,
    params,
    errorMessage,
  }: {
    prelandId: number;
    screenNumber: number;
    emailValidation?: boolean;
    params?: Record<
      string,
      string | number | boolean | SelectOption[] | undefined
    >;
    errorMessage?: Record<string, string | string[]>;
  }) {
    const { data } = await httpClient.post('/register/preland', {
      id: prelandId,
      screen_number: screenNumber,
      email_validation: emailValidation,
      params,
      ...(errorMessage && { error_message: errorMessage }),
    });

    return data;
  },

  logPaymentFormLoaded({
    orderId,
    result,
    iframeSrc,
    billingToken,
  }: {
    orderId: string | undefined;
    result: 1 | 0;
    iframeSrc?: string;
    billingToken?: string;
    userId?: string;
  }) {
    return httpClient.get('/track/payment/init-result', {
      params: {
        order_id: orderId,
        result,
        ...(Boolean(iframeSrc) && { form_script_src: iframeSrc }),
        ...(Boolean(billingToken) && { billing_token: billingToken }),
      },
    });
  },

  trackPaymentForm(payload: TrackPaymentFormPayload) {
    return httpClient.post('/track/payment/form', {
      order_id: payload.orderId,
      billing_token: payload.billingToken,
      event: payload.event,
      raw_response: payload.raw,
      pay_method: payload.payMethod,
      interaction_type: payload.interactionType,
      interaction_name: payload.interactionName,
      card_form_is_valid: payload.cardFormIsValid,
      card_form_touched: payload.cardFormTouched,
      card_number_is_valid: payload.cardNumberIsValid,
      card_number_touched: payload.cardNumberTouched,
      card_cvv_is_valid: payload.cardCvvIsValid,
      card_cvv_touched: payload.cardCvvTouched,
      card_expiry_is_valid: payload.cardExpiryIsValid,
      card_expiry_touched: payload.cardExpiryTouched,
      event_param: payload.eventParam,
      resize_width: payload.resizeWidth,
      resize_height: payload.resizeHeight,
      screen: payload.screen,
    });
  },

  fetchInitialId(traceId?: string) {
    axiosRetry(httpClient, {
      retries: 3,
      retryDelay: () => 100,
    });

    const origin = MirrorService.originForInitialId;

    return httpClient.get<{ initial_id: string }>(`${origin}/get-initial-id`, {
      params: {
        trace_id: traceId,
      },
    });
  },

  trackPwaInstall(payload: { is_system: number; icon: string }) {
    return httpClient.post('/pwa/install', payload);
  },

  trackPwaDelete(payload: { icon: string }) {
    return httpClient.post('/pwa/delete', payload);
  },

  trackLimitOfferSeen(payload: ScreenParameters & { pageUrl: string }) {
    return httpClient.post('/track/limit-time-offer/seen', {
      page_url: payload.pageUrl,
      screen: payload.screen,
    });
  },

  trackPageView(
    payload: ScreenParameters & {
      url: string;
      info?: string;
      billingToken?: string;
      campaign_id?: number;
    }
  ) {
    return httpClient.post('/track/events/page-view', {
      url: payload.url,
      screen: payload.screen,
      info: payload.info,
      campaign_id: payload.campaign_id,
      ...(Boolean(payload.billingToken) && {
        billing_token: payload.billingToken,
      }),
    });
  },

  trackPageLoad(
    payload: ScreenParameters & {
      url: string;
      info?: string;
      duration: number;
      message?: 'not_found_page';
    }
  ) {
    return httpClient.post('/track/events/page-load', {
      // @ts-ignore
      id: window?.pageLoadId,
      url: payload.url,
      info: payload.info,
      duration: payload.duration,
      screen: payload.screen,
      message: payload.message,
    });
  },

  trackScreenOrientation(payload: ScreenParameters) {
    return httpClient.post('/track/screen-rotate', payload);
  },

  trackPopup(
    payload: ScreenParameters & {
      info: string;
      operation: string;
      parameter?: string;
      value?: number;
    }
  ) {
    return httpClient.post('/track/popup', {
      info: payload.info,
      operation: payload.operation,
      parameter: payload.parameter,
      value: payload.value,
      screen: payload.screen,
    });
  },

  trackPublicPopup(
    payload: ScreenParameters & {
      info: string;
      operation: string;
      parameter?: string;
      value?: number;
    }
  ) {
    return httpClient.post('/track/public/popup', {
      info: payload.info,
      operation: payload.operation,
      parameter: payload.parameter,
      value: payload.value,
      screen: payload.screen,
    });
  },

  trackClick(
    payload: ScreenParameters & {
      value?: number;
      operation?: string;
      parameter?: string;
      info?: string;
      name?: string;
      extra_json?: any;
    }
  ) {
    return httpClient.post('/track/click', {
      value: payload.value,
      operation: payload.operation,
      parameter: payload.parameter,
      info: payload.info,
      name: payload.name,
      screen: payload.screen,
      extra_json: payload.extra_json,
    });
  },

  trackScroll(
    payload: ScreenParameters & {
      info: string;
      operation: string;
      parameter?: string;
      value?: number;
    }
  ) {
    return httpClient.post('/track/scroll', {
      info: payload.info,
      operation: payload.operation,
      parameter: payload.parameter,
      value: payload.value,
      screen: payload.screen,
    });
  },

  trackRecurringShow(payload: { orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      step: 'recurring',
      operation: 'show',
    });
  },

  trackRecurringClose(payload: { orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      step: 'recurring',
      operation: 'close',
    });
  },

  trackResignShow(payload: { orderId: string; orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: 'resign',
      operation: 'show',
    });
  },

  trackResignClose(payload: { orderId: string; orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: 'resign',
      operation: 'close',
    });
  },

  trackPaymentPopupClick(payload: {
    orderId?: string;
    step: 'recurring' | 'resign';
    orderType: OrderType;
  }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: payload.step,
      operation: 'click',
    });
  },

  track3dsVerifyShow(payload: { orderId: string; orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: '3ds_verify',
      operation: 'show',
    });
  },

  track3dsVerifyClose(payload: { orderId: string; orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: '3ds_verify',
      operation: 'close',
    });
  },

  track3dsVerifyClick(payload: { orderId: string; orderType: OrderType }) {
    return httpClient.post('/track/payment/popup', {
      order_type: payload.orderType,
      order: payload.orderId,
      step: '3ds_verify',
      operation: 'click',
    });
  },

  trackConfirmEmailStaticPopupShown(payload: ScreenParameters) {
    return httpClient.post('/track/email-confirm', {
      screen: payload.screen,
    });
  },

  trackDynamicBannerLoad(payload: {
    profileId?: number | null;
    profilesPackHash?: string;
    bannerUrl: string;
    pageUrl: string;
    requestTs: number;
    responseTs: number;
  }) {
    return httpClient.post('/track/ad-banner/load', {
      profile_id: payload.profileId,
      profiles_pack_hash: payload.profilesPackHash,
      banner_url: payload.bannerUrl,
      page_url: payload.pageUrl,
      request_ts: payload.requestTs,
      response_ts: payload.responseTs,
    });
  },

  // Deprecated use /track-popup
  trackTransferPopupAction(
    payload: ScreenParameters & {
      theme: string;
      operation: 'show' | 'click' | 'close';
      eventParameter?: string | null;
    }
  ) {
    return httpClient.post('/track/transfer/popup', {
      name: `transfer_${payload.theme}`,
      operation: payload.operation,
      event_parameter: payload.eventParameter,
      screen: payload.screen,
    });
  },

  trackFinderLoad(payload: {
    ts: number;
    profiles: UserCard[];
    screenWidth: number;
    screenHeight: number;
    paginationDepth: number;
    nextUrl: string | null;
    filters: Omit<FinderFilters, 'city'> & { city_id: number };
    profilesPackHash: string;
  }) {
    return httpClient.post('/track/finder/load', {
      ts: payload.ts,
      profiles: payload.profiles,
      screen_width: payload.screenWidth,
      screen_height: payload.screenHeight,
      pagination_depth: payload.paginationDepth,
      next_url: payload.nextUrl,
      filter_params_data: payload.filters,
      profiles_pack_hash: payload.profilesPackHash,
    });
  },

  trackVideoCastLoad(payload: {
    ts: number;
    profiles: Array<UserVideoCardWithViews>;
    paginationDepth: number;
    nextUrl: string | null;
    profilesPackHash: string;
  }) {
    return httpClient.post('/track/finder/cam-video/load', {
      ts: payload.ts,
      profiles: payload.profiles,
      pagination_depth: payload.paginationDepth,
      next_url: payload.nextUrl,
      profiles_pack_hash: payload.profilesPackHash,
    });
  },

  trackFinderMiniVideoPlayer(payload: {
    videoId: number;
    action: 'play' | 'auto_stop' | 'close' | 'click';
    profilesPackHash: string;
  }) {
    return httpClient.post('/track/pic-in-pic', {
      video_id: payload.videoId,
      action: payload.action,
      profiles_pack_hash: payload.profilesPackHash,
    });
  },

  trackFullScreenVideoPlayer(payload: {
    videoId: number;
    action: 'play' | 'auto_stop' | 'close' | 'chat' | 'continue';
    profilesPackHash?: string;
  }) {
    return httpClient.post('/track/video/show', {
      video_id: payload.videoId,
      action: payload.action,
      profiles_pack_hash: payload.profilesPackHash,
    });
  },

  trackGiftsShow(payload: {
    ts: number;
    ids: number[];
    listLype: 'full' | 'short';
  }) {
    return httpClient.post('/track/gifts/show', {
      ts: payload.ts,
      ids: payload.ids,
      list_type: payload.listLype,
    });
  },

  trackGtmPostback(payload: { info: string; parameter?: string }) {
    return httpClient.post('/track/tracking-postback', {
      info: payload.info,
      parameter: payload.parameter,
    });
  },

  trackBackButton({
    prelandId,
    screenNumber,
    channel,
    params,
  }: {
    prelandId: number;
    screenNumber: number;
    channel: BackButtonTraffickChannels;
    params?: Record<
      string,
      string | number | boolean | SelectOption[] | undefined
    >;
  }) {
    return httpClient.post(`/track/${channel}`, {
      id: prelandId,
      screen_number: screenNumber,
      params,
    });
  },

  trackReactChunkLoadEnd() {
    const reactLoadEndAt = Date.now();

    return httpClient.post('/track/react-load-end', {
      // @ts-ignore
      id: window?.pageLoadId,
      end_at: reactLoadEndAt,
      // @ts-ignore
      duration: reactLoadEndAt - window.appLoadStartTime || 0,
    });
  },

  // @ts-ignore
  trackAppLoadEnd() {
    const appLoadEndAt = Date.now();

    httpClient.post('/track/app-load-end', {
      // @ts-ignore
      id: window?.pageLoadId,
      end_at: appLoadEndAt,
      // @ts-ignore
      duration: appLoadEndAt - window.appLoadStartTime || 0,
      // @ts-ignore
      thru_tds: !!window?.thruTds,
    });

    // @ts-ignore
    window.thruTds = false;
  },

  trackTdsLoadEnd() {
    const tdsLoadEndAt = Date.now();

    // @ts-ignore
    window.thruTds = true;

    return httpClient.post('/track/tds-load-end', {
      // @ts-ignore
      id: window?.pageLoadId,
      end_at: tdsLoadEndAt,
      // @ts-ignore
      duration: tdsLoadEndAt - window.appLoadStartTime || 0,
    });
  },

  async trackLogout(id: number) {
    await httpClient.post('/track/logout', {
      id,
    });
  },

  async trackDeleteAccount(id: number) {
    await httpClient.post('/track/delete-profile', {
      id,
    });
  },

  trackInitLoadEnd({
    initLoadStart,
    initId,
  }: {
    initLoadStart: number;
    initId: string;
    userId?: string;
  }) {
    const initLoadEndAt = Date.now();

    return httpClient.post('/track/init-load-end', {
      duration: initLoadEndAt - initLoadStart,
      end_at: initLoadEndAt,
      id: initId,
    });
  },

  trackAdBlockStatus(
    payload: ScreenParameters & {
      value: number;
      parameter?: string;
    }
  ) {
    return httpClient.post('/track/ad-block', {
      screen: payload.screen,
      value: payload.value,
      parameter: payload.parameter || '',
    });
  },

  trackSingleSignOn(
    payload: ScreenParameters & {
      info: string;
      operation: string;
      jwt: string;
    }
  ) {
    return httpClient.post('/track/single-sign-on', {
      info: payload.info,
      operation: payload.operation,
      screen: payload.screen,
      jwt: payload.jwt,
    });
  },

  trackInterstitial(
    payload: ScreenParameters & {
      operation: string;
      info?: string;
      parameter?: string;
      value?: number;
    }
  ) {
    return httpClient.post('/track/interstitial', {
      operation: payload.operation,
      info: payload.info,
      parameter: payload.parameter,
      value: payload.value,
      screen: payload.screen,
    });
  },
};
