export const NO_LIKES_TEXT = 'New likes are coming';

export const CREDITS_WELCOME_PACK_SESSION_STORAGE_KEY = 'CREDIT_WELCOME_PACK';
export const VIP_RESUBSCRIBE_SESSION_STORAGE_KEY = 'VIP_RESUBSCRIBE';

export const PROJECT_COOKIES_SETTINGS_KEY = 'PROJECT_COOKIES_SETTINGS';
export const COOKIES_SETTINGS_SHOWN_KEY = 'COOKIES_SETTINGS_SHOWN';

export const INTERESTS_BACKGROUNDS: string[] = [
  '#FFE2DB',
  '#DBFFE5',
  '#DBEEFF',
  '#E7DBFF',
  '#FFDBFE',
];

export const IS_INIT_STICKERS_SHOWN_SESSION_STORAGE_KEY = 'stickersInit';

export const LOCATION_KEY = 'COUNTRY';

export const ORGANIC_PARTNER_IDS = ['1', '20'];
